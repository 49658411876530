import React from "react";
import '../../assets/css/landingpage.css'


import logoImage from "../../../src/assets/img/images/kwonG.gif";
import katImage from "../../../src/assets/img/images/katlogo.gif";

const Banner = (props) => {
  return (
   
<div className="landingpage">
      
<div className="container">
        <div className="row justify-content-center">
          
            <div className="banner-content text-center">
            
              <h2 className="title">
              <img src={katImage} width={600} height={175} alt='katkwondojo' />
                
              </h2>
              <img src={logoImage} width={250} height={250} alt='katkwondo' />
              
            </div>
            <div className="section-title mb-30">
               
              
              </div>
            <div className="banner-countdown-wrap text-center"><center>
            <h2 className="title">A bloodsport meme side kicking his way to Coinmarketcap TOP 10!</h2>
            <h2> <a href="https://dexscreener.com/solana/7ct2et4ngonrr3ru5u4txmfmqfjakadbqnqbwahcfmw">CHART</a> - <a href="https://twitter.com/katkwondojo">TWITTER</a> - <a href="https://t.me/katkwondo">TELEGRAM</a> </h2>
            
           <h3> <a href="https://jup.ag/swap/SOL-2sf7QnRtbjGFRMV9QwLVpAsiUcvN51RUAqnZsuC8KLpu">CA: 2sf7QnRtbjGFRMV9QwLVpAsiUcvN51RUAqnZsuC8KLpu</a> </h3>
           <h3> <a href=""></a> </h3></center>
           
            <div className="section-title mb-30">
            </div>
              </div>
              
                  
                </div>
          
            </div>
          </div>
       
      
 
   
  );
};

export default Banner;
