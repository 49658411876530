import React from "react";
import Banner from "../../components/Banner/Banner";

import LayoutOne from "../../layouts/LayoutOne";


const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        
      </main>
    </LayoutOne>
    
  );
};

export default Home;
